<template>
  <Page class="page-categories page-list-categories">
    <template #title>Categorie</template>

    <template #title-extra>
      <router-link class="btn btn-outline-light" :to="{ name: 'categories.new' }">Nuova categoria</router-link>
    </template>

    <template #default>
      <template v-if="categories && categories.length > 0">
        <AwlTable :heading="tableHeadings" :rows="categories" striped>
          <template #actions-content="{ row }">
            <ActionShow :to="{ name: 'categories.show', params: { id: row.id } }" />
          </template>
        </AwlTable>
      </template>
      <Alert v-else status="info">Nessuna categoria presente</Alert>
    </template>
  </Page>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal.js';

import Page from '@/views/components/Private/Page.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import ActionShow from '@/libs/Table/components/Actions/ActionShow.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  $api.listCategories()
    .then(res => next(vm => {
      vm.categories = res?.data?.categories || [];
    }))
    .catch(() => next(from))
  ;
}

export default {
  mixins: [confirmWithModalMixin],
  components: {
    Page,
    AwlTable,
    ActionShow,
    Alert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  data: () => ({ categories: [] }),
  computed: {
    tableHeadings () {
      return [
        { key: 'name', label: 'Nome' },
        { key: 'rank', label: 'Ordinamento' },
      ];
    },
  },
  methods: {
    fetchResults () {
      return $api.listCategories()
        .then(res => {
          this.categories = res?.data?.categories || [];
        })
        .catch(() => this.$log.error)
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
